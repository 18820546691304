/* *{
  transition: 2s all;
} */

.card {
  cursor: pointer !important;
  transition: all .5s;
}

.card:hover {
  transform: scale(1.05);
  transition: all .5s;
  box-shadow: 0px 5px 20px rgba(0,0,0,.2) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rt-tr-group{
  transition: all .5s;
}
.rt-tr-group:hover{
  transition: all .5s;
  /* transform: scale(1.01); */
  background-color: #f8f8f8;
  box-shadow: 0px 0px 10px 0px #0000011a;
  z-index: 10;
}
@import url(https://fonts.googleapis.com/css?family=Poppins:400,600,800&display=swap);
/* *{
  transition: 2s all;
} */

.card {
  cursor: pointer !important;
  transition: all .5s;
}

.card:hover {
  transform: scale(1.05);
  transition: all .5s;
  box-shadow: 0px 5px 20px rgba(0,0,0,.2) !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.rt-tr-group{
  transition: all .5s;
}
.rt-tr-group:hover{
  transition: all .5s;
  /* transform: scale(1.01); */
  background-color: #f8f8f8;
  box-shadow: 0px 0px 10px 0px #0000011a;
  z-index: 10;
}
* {
  font-family: 'Poppins', sans-serif;
}

.animatedTransition {
  transition: stroke-dashoffset 3s;
}

.clickable {
  cursor: pointer !important;
  transition: all .5s;
}

.clickable:hover {
  transform: scale(1.02);
  transition: all .5s;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .1) !important;
}

.clickableMap {
  cursor: pointer !important;
  transition: all .2s;
}

.clickableMap:hover {
  transform: scale(1.3);
  transition: all .2s;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, .1) !important;
}

a, a *{
  text-decoration: none;
}

label{
  margin-bottom: 10px;
  color:#170747
}
.basicInput {
  height:40px;
  padding-left:10px;
  padding-right:10px;
  border-radius: 5px;
  border-color: #170747;
  border-width: 1px;
}
